@font-face {
  font-family: 'Rockybilly';
  src: url(../public/Rockybilly.ttf), ;
  font-family: 'Dharma Punk';
  src: url(../public/Dharma-Punk.ttf);
}

#textblock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../public/aboutus-back.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
}

#textblock-container {
  width: 90vw;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  /* Set background to transparent black */
  padding: 5%;
  border-radius: 12px;
  backdrop-filter: blur(30px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Title and Subtitle */
#title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

#textblock-title {
  font-size: 6rem;
  font-weight: bold;
  /* font-family: ''; */
  color: #ffffff;
  white-space: nowrap;
  ;
  text-transform: uppercase;
}

#textblock-subtitle {
  font-size: 1.7rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  margin-top: -5px;
}

/* Text Content */
#textblock-content {
  font-size: 1rem;
  line-height: 1.8;
  color: #ffaf1b;
  margin-top: 10px;
}

/* Logo */
#head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

#logo {
  width: 60rem;
  height: auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  #textblock-title {
    font-size: 3.5rem;
  }

  #textblock-content {
    font-size: 1.1rem;
  }

  #logo {
    width: 200px;
    height: auto;
  }
}

@media (max-width: 768px) {
  #textblock-container {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    padding-left: 35%;
    padding-right: 35%;
  }

  #textblock-title {
    font-size: 3rem;
  }

  #textblock-content {
    font-size: 1rem;
  }

  #logo {
    width: 120px;
  }
}

@media (max-width: 480px) {
  #textblock-container {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    padding-left: 50%;
    padding-right: 50%;
  }

  #textblock-title {
    font-size: 2.5rem;
  }

  #textblock-subtitle {
    font-size: 1rem;
  }

  #textblock-content {
    font-size: 0.95rem;
  }

  #logo {
    width: 100px;
  }
}