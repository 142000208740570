@font-face {
    font-family: Rockybilly;
    src: url(../../../../public/Rockybilly.ttf);
}

.schedule-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #757575;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../../public/schedule-back.png);
    color: white;
    background-size: cover;
    background-position: center;
}

.event_top_mid_schdeule h1 {
    /* text-align: center; */
    margin-top: 5%;
    margin-left: 5%;
    font-family: Rockybilly;
    color: white;
    -webkit-text-stroke: 0px #111010;
    font-size: 2vw;
    /* margin: 3; */

}

.day-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    padding: 30px;
    /* background-color: rgb(255, 255, 255); */
    /* margin-bottom: 3%; */

}


.day-button {
    /* padding: 10px; */
    border: 1px solid black;
    cursor: pointer;
    color: black;
    width: 20%;
    border: 4px solid #000;
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 10px 10px 0 #000;
    font-family: "Arial", sans-serif;
    /* margin-top: 20px; */
}

@media screen and (max-width:900px) {
    .event_top_mid_schdeule h1 {
        margin-top: 15%;
        font-size: 30px;
        text-align: center;
    }
}

.selected-day {
    background-color: rgb(0, 0, 0);
    color: rgb(3, 3, 3);
    width: 20%;
    border: 4px solid #000;
    background-color: #ffaf1b;
    padding: 1.5rem;
    box-shadow: 10px 10px 0 #000;
    font-family: "Arial", sans-serif;
    /* margin-top: 20px; */
}

.selected-category {
    /* background-color: pink; */
    padding: 10px;
    margin-bottom: 5px;
    background-color: #000000;
    border: 2px solid black;
    cursor: pointer;
    color: black;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 5px 5px 0px black;
}

@media screen and (max-width:768px) {
    .event_top_mid_schdeule h1 {
        margin-top: 15%;
        font-size: 25 px;
        text-align: center;
    }

    .day-selection {
        gap: 0px;
        overflow: hidden;
    }

    .day-button {
        padding: 0.55rem;
    }

    .selected-day {
        padding: 0.55rem;
    }
}

@media screen and (max-width:530px) {
    .event_top_mid h1 {
        margin-top: 17%;
        font-size: 25 px;
        text-align: center;
    }
}



.event-type-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

}

.type-button {
    /* padding: 10px; */
    margin: 2%;
    cursor: pointer;
    color: black;
    /* width: 15%; */

    padding: 1rem;

    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 16px 20px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #000000 5%);
    border: 1;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1;
    box-shadow: 6px 0px 0px #ffaf1b;
    outline: transparent;
    position: relative;

    /* margin-top: px; */
}

.selected-tech {
    /* padding: 10px; */
    margin: 2%;
    cursor: pointer;
    color: black;
    /* width: 15%; */
    border: 4px solid #000;
    background-color: #ffaf1b;
    padding: 1rem;
    box-shadow: 10px 10px 0 #000;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-top: px; */
    padding: 16px 20px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #000000 5%);
    border: 1;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1;
    box-shadow: 6px 0px 0px #ffaf1b;
    outline: transparent;
    position: relative;
}

.selected-cultural {
    /* padding: 10px; */
    margin: 2%;
    cursor: pointer;
    color: black;
    /* width: 15%; */
    border: 4px solid #000;
    background-color: #ffaf1b;
    padding: 1rem;
    box-shadow: 10px 10px 0 #000;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-top: px; */
    padding: 16px 20px;
    font-size: 18px;
    background: linear-gradient(45deg, transparent 5%, #000000 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    line-height: 1;
    box-shadow: 6px 0px 0px #ffaf1b;
    outline: transparent;
    position: relative;
}

.schedule-content {
    display: flex;
    /* flex: 1; */
}

.category-sidebar {
    display: flex;
    /* flex-direction: column; */
    /* width: 20%; */
    padding: 10px;
}

.category-button {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #ffaf1b;
    border: 2px solid black;
    cursor: pointer;
    color: black;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 5px 5px 0px black;
}



.event-details {
    padding-top: 20%;
    text-align: center;
    padding: 20px;
    font-size: xx-large;
    font-weight: 400;
    /* border-radius: 5px; */
    /* color: black; */
    overflow: auto;
    background-color: rgb(0, 0, 0);
    color: rgb(3, 3, 3);
    width: 100%;
    height: 100%;
    border: 6px solid #000;
    background-color: #ffd500;
    padding: 1.5rem;
    box-shadow: 10px 10px 0 #505050;
    font-family: "Arial", sans-serif;
    margin-top: -20px;
    border-radius: 10px;

}

.event-item {
    padding: 2%;
    display: flex;
    justify-content: flex-start;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    text-align: center;
    font-size: x-large;
}

button::after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
    content: "HOVER ME";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00e6f6 3%, #00e6f6 5%, #ff013c 5%);
    text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #00e6f6;
    clip-path: var(--slice-0);
}

.type-button:hover::after,
.selected-tech:hover::after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
}

@keyframes glitch {
    0% {
        clip-path: var(--slice-1);
        transform: translate(-20px, -10px);
    }

    10% {
        clip-path: var(--slice-3);
        transform: translate(10px, 10px);
    }

    20% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 10px);
    }

    30% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }

    40% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }

    50% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }

    60% {
        clip-path: var(--slice-4);
        transform: translate(5px, 10px);
    }

    70% {
        clip-path: var(--slice-2);
        transform: translate(-10px, 10px);
    }

    80% {
        clip-path: var(--slice-5);
        transform: translate(20px, -10px);
    }

    90% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
    }

    100% {
        clip-path: var(--slice-1);
        transform: translate(0);
    }
}