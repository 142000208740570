.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Make the video cover full viewport height */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the video covers the entire section */
}

/* Gradient Overlay for Smooth Blending */
.video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, #000000);
  z-index: 1;
  /* Ensures overlay appears above video */
}

.video-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(8deg, rgba(36, 30, 0, 0.203) 0%, rgba(20, 20, 4, 1) 100%);~ */
  z-index: 2;
}

/* Ensure sections above and below the video have a smooth blend */
.video-container+div {
  margin-top: -5px;
  /* Slight overlap to prevent gaps */
}