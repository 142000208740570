@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Outfit:wght@100..900&family=Prata&display=swap");

@font-face {
  font-family: Rockybilly;
  src: url(../../../public/Rockybilly.ttf);
}

.events {
  /* background-color: #f8f8f8; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 25vh;
  /* Added space from top */
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)),
    url("https://res.cloudinary.com/diiyq5fwx/image/upload/v1742742028/background_image_desk_ju9x1p.webp");

  background-attachment: fixed;

  background-size: cover;
  /* background-position: ; */
}

.event_top {
  margin-bottom: 20vh;
  /* Space for next section */
  text-align: center;
}

.event_top_top {
  font-size: x-large;
  margin-top: 10%;
  width: 100%;

}

.event_top_top h1 {
  /* font-family: Rockybilly; */
  color: #ffffff;
  -webkit-text-stroke: 0px #ffffff;
  font-size: 2vw;
  margin: 3;
}

.event_top_mid h1 {
  font-family: Rockybilly;
  color: #ffffff;
  -webkit-text-stroke: 0px #ffffff;
  font-size: 6vw;
  margin: 3;

}

.event_top_bot button {
  padding: 10px 20px;
  border-radius: 10px;
  outline: none;
  border: 4px solid #ffaf1b;
  background-color: #4b0000;
  color: #ffaf1b;
  font-size: 1.5vw;
  cursor: pointer;
}

.event_mid {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.event_mid_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10vh;
  margin-bottom: 20px;
}

.event_mid_top h1 {
  font-size: 10vw;
  font-weight: bold;
  margin: 0;
}

.event_mid_mid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  transform: translate(-50%);
  position: relative;
  /* tran */
  left: 50%;
  column-gap: 10px;
  width: 100vw;
  margin-top: 20px;
  justify-content: space-evenly;
  /* margin: 0 auto; */
}

.event_mid .event_mid_mid .container {
  margin-top: 20px;
  border: 1px solid #ffaf1b;
  width: 400px;
  height: 400px;
}

@media screen and (max-width: 1310px) {
  .event_mid_mid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Added media query for smaller screens */
@media screen and (max-width: 810px) {
  .event_mid .event_mid_mid .container {
    margin-top: 20px;
    border: 1px solid #ffaf1b;
    width: 600px;
    height: 600px;
  }

  .event_mid_mid {
    grid-template-columns: 1fr;
    /* Single column layout */
    width: 100%;
  }
}

@media screen and (max-width: 610px) {
  .event_mid .event_mid_mid .container {
    margin-top: 20px;
    border: 1px solid #ffaf1b;
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 425px) {
  .event_mid .event_mid_mid .container {
    margin-top: 20px;
    border: 1px solid #ffaf1b;
    width: 300px;
    height: 300px;
  }
}

.brutalist-card {
  width: 380px;
  border: 4px solid #000;
  background-color: #ffffff;
  padding: 1.5rem;
  box-shadow: 10px 10px 0 #000;
  font-family: "Arial", sans-serif;
  margin-top: 20px;
}



@media screen and (max-width: 400px) {
  .brutalist-card {
    width: 340px;
  }

  .brutalist-card_butt .btn-class-name {
    width: 120px;
  }
}

.brutalist-card__header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
}

.brutalist-card__icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 0.5rem;
}

.brutalist-card__icon svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: #fff;
}

.brutalist-card__alert {
  font-weight: 900;
  color: #000;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.brutalist-card__message {
  margin-top: 1rem;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.4;
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
  font-weight: 600;
}

.brutalist-card__actions {
  margin-top: 1rem;
}

.brutalist-card__button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  /* border: 3px solid #000; */
  background-color: #fff;
  color: #280003;
  position: relative;
  /* transition: all 0.2s ease; */
  box-shadow: 5px 5px 0 #000;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 1rem;
  background-color: #ffaf1b;
}

.brutalist-card__button:active {
  transform: translate(5px, 5px);
  box-shadow: none;
}

.btn-class-name {
  --bg: #ffaf1b;
  --text-color: #fff;
  position: relative;
  width: 140px;
  border: none;
  background: var(--bg);
  color: #000;
  padding: 1em;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.1s;
  border-radius: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
  box-shadow: #000 0px 7px 2px, #000 0px 8px 5px;
}

button:hover {
  background: #000;
  color: #fff;
  opacity: 1;
}

button:active {
  top: 4px;
  /* box-shadow: #ffffff 0px 3px 2px,#000 0px 3px 5px; */
}

.btn-class-name .back {
  background: rgb(var(--secondary));
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 70px;
}

.btn-class-name .front {
  background: linear-gradient(0deg,
      rgba(var(--primary), 0.6) 20%,
      rgba(var(--primary)) 50%);
  box-shadow: 0 0.5em 1em -0.2em rgba(var(--secondary), 0.5);
  border-radius: 100%;
  position: absolute;
  border: 1px solid rgb(var(--secondary));
  left: 0;
  top: 0;
  width: 90px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: inherit;
  transform: translateY(-15%);
  transition: 0.15s;
  color: rgb(var(--secondary));
  color: #000;
}

.btn-class-name:active .front {
  transform: translateY(0%);
  box-shadow: 0 0;
}

.brutalist-card__actions {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 20px;
}

.brutalist-card_butt {
  display: flex;
  justify-content: space-between;
}

.no-results {
  display: flex;
  font-family: Rockybilly;
  color: #ffffff;
  -webkit-text-stroke: 0px #ffffff;
  /* border: 2px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  /* margin-left: 20rem; */
  width: 100vw;
  font-size: 2rem;
  text-align: center;
}
