/* .container {
  width: 100vh;
  background-color: #fff;
} */
/* VIDEO SECTION CONTAINER */
.video-container {
  /* position: relative; */
  /* z-index: 1; */
  background: black; /* Ensure background matches MegaEvents */
}

/* BLENDING EFFECT BETWEEN SECTIONS */
.transition-blur {
  /* position: relative; */
  width: 100%;
  height: 80px; /* Adjust for smooth blending */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(10, 10, 10, 1) 100%
  );
  filter: blur(10px); /* Blurs the transition for a softer blend */
  /* z-index: 2; */
}

/* MEGA EVENTS SECTION */
.mega-events {
  background: #0a0a0a; /* Dark background to match VideoIntro */
  color: white; /* Ensure readability */
  padding: 50px 20px;
}
