.button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button_event{
    cursor: pointer;
    border: none;
    background: #ffaf1b;
    color: #4b0000;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    transition:
      background 300ms,
      transform 200ms;
    font-weight: 600;
  }
  
  .button__text {
    position: absolute;
    inset: 0;
    animation: text-rotation 8s linear infinite;
  }
  
  .button__text > span {
    position: absolute;
    transform: rotate(calc(19deg * var(--index)));
    inset: 7px;
  }
  
  .button__circle {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: #4b0000;
    color: #ffaf1b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button__icon--copy {
    position: absolute;
    transform: translate(-150%, 150%);
  }
  
  .button:hover {
    background: #000;
    transform: scale(1.05);
  }
  
  .button:hover .button__icon {
    color: #000;
  }
  
  .button:hover .button__icon:first-child {
    transition: transform 0.3s ease-in-out;
    transform: translate(150%, -150%);
  }
  
  .button:hover .button__icon--copy {
    transition: transform 0.3s ease-in-out 0.1s;
    transform: translate(0);
  }
  
  @keyframes text-rotation {
    to {
      rotate: 360deg;
    }
  }
  