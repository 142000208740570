.merch-head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styled-wrapper {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.merch-button {
  cursor: pointer;
  border: none;
  background: #000000;
  color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: grid;
  place-content: center;
  transition:
    background 300ms,
    transform 200ms;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.merch-button__text {
  position: absolute;
  inset: 0;
  animation: text-rotation 6s linear infinite;
}

.merch-button__text>span {
  position: absolute;
  transform: rotate(calc(18deg * var(--index)));
  inset: 8px;
  font-size: 14px;
  font-weight: bold;
}

.merch-button__circle {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #fff;
  color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.merch-button__icon--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.merch-button:hover {
  background: #d84315;
  transform: scale(1.1);
}

.merch-button:hover .merch-button__icon {
  color: #000000;
}

.merch-button:hover .merch-button__icon:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.merch-button:hover .merch-button__icon--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

@keyframes text-rotation {
  to {
    rotate: 360deg;
  }
}




/* ✅ Fixing Card Overlapping */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1%;
  gap: 5%;
  width: 100%;
  padding: 2% 0;
}

/* ✅ Ensure Cards Do Not Overlap */
.card {
  width: 40%;
  height: auto;
  min-height: 300px;
  /* Ensures enough space */
  perspective: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* Prevent overlap */
}

/* ✅ Prevent Cards from Overlapping */
.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ✅ Responsive Layout for Mobile */
@media screen and (max-width: 768px) {
  .card-container {
    flex-direction: column;
    /* Stack cards vertically */
    align-items: center;
    gap: 30px;
    /* Extra space between stacked cards */
  }

  .card {
    width: 80%;
    /* Increase width for better visibility */
    min-height: 250px;
  }
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

/* Ensuring proper alignment */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
}

/* ✅ Ensure Images Scale Properly */
.mercht {
  width: 90%;
  height: auto;
}