@keyframes bobble {

  0%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(3px);
  }

  75% {
    transform: translateY(-2px);
  }
}

.hover\:animate-bobble:hover {
  animation: bobble 0.6s ease-in-out infinite;
}

.container-contact {
  /* height: 100vh; */
  /* position: absolute; */
  top: 0;
  right: 0;
  left: 0;
  overflow: auto;
}

.contact-1 {
  margin-top: 8%;
  width: auto;
  color: black;
  font-size: xx-large;
  /* background-color: bisque; */
  gap: 1rem;

}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  background: black;
  padding: 20px;
  z-index: 1;
}

.contact-item::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: yellow;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  z-index: -1;
}

.contact-item:last-child::after {
  display: none;
  /* Hide line after last item */
}