.navbar {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
}

.Logo_navbar123 {
  width: 120px;
  margin-left: 20px;

  position: absolute;
  top: 1%;
  left: 0;
}

.logo img {
  position: absolute;
  left: 0;
  padding-left: 1rem;
  width: 30px;
  /* Reduced size */
  height: 30px;
  /* Reduced size */
  background: none;
  /* Removed background */
}

.navbar_item {
  display: none;
}

.button {
  --stone-50: #280003;
  --stone-800: #280003;
  --yellow-400: #ffaf1b;
  width: auto;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  line-height: 1;
  padding: 1px;
  transform: translate(-4px, -4px);
  outline: 2px solid transparent;
  outline-offset: 5px;
  border-radius: 9999px;
  background-color: var(--stone-800);
  color: var(--stone-800);
  transition: transform 150ms ease, box-shadow 150ms ease;
  text-align: center;
  box-shadow: 0.5px 0.5px 0 0 var(--stone-800), 1px 1px 0 0 var(--stone-800),
    1.5px 1.5px 0 0 var(--stone-800), 2px 2px 0 0 var(--stone-800),
    2.5px 2.5px 0 0 var(--stone-800), 3px 3px 0 0 var(--stone-800),
    0 0 0 2px var(--stone-50), 0.5px 0.5px 0 2px var(--stone-50),
    1px 1px 0 2px var(--stone-50), 1.5px 1.5px 0 2px var(--stone-50),
    2px 2px 0 2px var(--stone-50), 2.5px 2.5px 0 2px var(--stone-50),
    3px 3px 0 2px var(--stone-50), 3.5px 3.5px 0 2px var(--stone-50),
    4px 4px 0 2px var(--stone-50);

  &:hover {
    transform: translate(0, 0);
    box-shadow: 0 0 0 2px var(--stone-50);
  }

  &:active,
  &:focus-visible {
    outline-color: var(--yellow-400);
  }

  &:focus-visible {
    outline-style: dashed;
  }

  &>div {
    position: relative;
    pointer-events: none;
    background-color: var(--yellow-400);
    /* border: 2px solid rgba(255, 255, 255, 0.3); */
    border-radius: 9999px;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 9999px;
      opacity: 0.5;
      background-image: radial-gradient(rgb(255 255 255 / 80%) 20%,
          transparent 20%),
        radial-gradient(rgb(255 255 255 / 100%) 20%, transparent 20%);
      background-position: 0 0, 4px 4px;
      background-size: 8px 8px;
      mix-blend-mode: hard-light;
      animation: dots 0.5s infinite linear;
    }

    &>span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem 1.25rem;
      gap: 0.25rem;
      filter: drop-shadow(0 -1px 0 rgba(255, 255, 255, 0.25));

      &:active {
        transform: translateY(2px);
      }
    }
  }
}

@keyframes dots {
  0% {
    background-position: 0 0, 4px 4px;
  }

  100% {
    background-position: 8px 0, 12px 4px;
  }
}

@media (max-width: 900px) {
  .navbar {
    display: none;
  }

  .Logo_navbar123 {
    display: none;
  }

  .Logo_navbar123 img {
    display: none;
  }

  .navbar_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background-color: #280003;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .menu-icons {
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }

  .logo img {
    width: 70px;
    height: 70px;
    transition: all 0.3s ease;
    margin-bottom: 30px;
    left: 10%;
    /*Center horizontally*/
    transform: translate(-50%, -50%);
    /* Adjust to center */
    top: 50%;
  }

  #navbar_id a {
    text-decoration: none;
    color: #fff;
  }

  #navbar_id {
    /* display: flex; */
    position: fixed;
    right: -300px;
    width: 300px;
    height: 100vh;
    color: #fff;
    list-style: none;
    top: 80px;
    background-color: #280003;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.5);
    padding: 90px;
    transition: 0.5s ease-in-out;
  }

  #navbar_id.active {
    right: 0px;
  }

  #navbar_id li {
    font-size: 24px;
    margin-bottom: 30px;
  }
}